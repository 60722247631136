import { ClassNames, Global } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css, theme } from 'twin.macro'
import { ReactComponent as Caret } from '../images/icon-caret-down.svg'
import { global } from '../styles/global'
import { max } from '../utils/breakpoints'
import { LinkType, RequiredLinkType } from '../utils/prop-types'
import Hamburger from './hamburger'
import Heading from './heading'
import Link from './link'
import Text from './text'

const Dropdown = ({ item }) => {
  
  const displayMenuElements = (sublink, i) => {
    let linkContent = sublink.link === "/" ? 
      <div css={tw`pt-10`}>
        <div
            css={[
              i !== 4 && global`typography.subtitle-lg`,
              tw`mt-5 font-bold text-primary-500`,
              i === 4 &&
                tw`text-xs font-medium uppercase cursor-default text-secondary-910`,
            ]}
          >
            {sublink.label}
          </div>
          <Text
            content={sublink.descriptionNode}
            style={[global`typography.subtitle-lg`, tw`mt-4 text-primary-500`]}
          />
      </div> 
      : 
      <ClassNames>
      {({ css: cx }) => (
        <Link
          to={sublink.link}
          activeClassName={cx(
            css`
              &:after {
                ${tw`translate-x-0! opacity-100!`}
              }
            `
          )}
          style={[
            css`
              ${tw`h-full pt-10`}
              &:hover {
                img {
                  ${tw`transform -translate-y-1`}
                }
              }
            `,
            sublink?.subLinks?.length <= 4
              ? css`
                  ${tw`pt-10`}
                `
              : '',
            i !== 4 && global`base.hover-underline`(),
          ]}
          partiallyActive
        >
          {sublink?.image && (
            <img
              src={sublink?.image?.url}
              css={tw`object-contain h-8 transition-transform duration-300 ease-in-out max-w-nav-icon`}
              alt=""
            />
          )}
          <div
            css={[
              i !== 4 && global`typography.subtitle-lg`,
              tw`mt-5 font-bold text-primary-500`,
              i === 4 &&
                tw`text-xs font-medium uppercase cursor-default text-secondary-910`,
            ]}
          >
            {sublink.label}
          </div>
          <Text
            content={sublink.descriptionNode}
            style={[global`typography.subtitle-lg`, tw`mt-4 text-primary-500`]}
          />
        </Link>
      )}
    </ClassNames>

    return linkContent;
  }

  return (
    <div
      css={[
        css`
          ${tw`hidden`}
          ${tw`lg:(flex pointer-events-none transition-opacity duration-300 ease-in-out opacity-0 fixed top-desktop-header inset-x-0 bg-white)`}
        `,
        item?.subLinks?.length < 4 && item.subLinks[0].image === null
          ? css`
              height: 11.5rem;
            `
          : css`
              height: 14.5rem;
            `,
        item?.subLinks?.length >= 4 &&
          css`
            height: 16.5rem;
          `,
      ]}
    >
      <ul
        css={[
          item?.subLinks?.length <= 4 ? global`layout.grid` : global`layout.grid-nav`,
          global`layout.container`,
        ]}
      >
        {item.subLinks.map((sublink, i) => (
          <li
            key={sublink.label}
            css={[
              tw`flex flex-col pr-6`,
              sublink?.subLinks?.length <= 4 ? tw`col-span-4` : tw`col-span-3`,
            ]}
          >
            {displayMenuElements(sublink, i)}
          </li>
        ))}
      </ul>
    </div>
  )
}

Dropdown.propTypes = {
  item: RequiredLinkType,
}

const DropdownNavItem = ({ item }) => {
  return (
    <li
      css={[
        css`
          ${tw`h-full`}
          &:hover {
            button + div {
              ${tw`opacity-100 pointer-events-auto`}
            }
            svg {
              ${tw`transform -rotate-180`}
            }
          }
        `,
        global`base.hover-underline()`,
      ]}
    >
      <button type="button" css={tw`flex items-center h-full`}>
        <span css={[global`typography.subtitle-lg`, tw`font-medium text-left`]}>{item.label}</span>
        {item.subLinks && item.subLinks.length ? (
          <Caret css={tw`ml-2 transition-transform duration-300 ease-in-out fill-secondary`} />
        ) : null}
      </button>
      {item.subLinks && item.subLinks.length ? <Dropdown item={item} /> : null}
    </li>
  )
}

DropdownNavItem.propTypes = {
  item: RequiredLinkType,
}

// Non-Nested individual LI item
const NavItem = ({ item }) => (
  <ClassNames>
    {({ css: cx }) => (
      <li
        css={css`
          ${tw`relative h-full`}
        `}
      >
        <Link
          to={item.link}
          activeClassName={cx(
            css`
              &:after {
                ${tw`translate-x-0! opacity-100!`}
              }
            `
          )}
          style={[
            css`
              ${tw`flex items-center h-full`}
            `,
            global`base.hover-underline`(),
          ]}
          partiallyActive
        >
          <span css={[global`typography.subtitle-lg`, tw`font-medium`]}>{item.label}</span>
        </Link>
      </li>
    )}
  </ClassNames>
)

NavItem.propTypes = {
  item: RequiredLinkType,
}

const MobileMenu = React.forwardRef(
  ({ isOpened, primaryLinks, secondaryLinks, socialMedia, copyright, forceCloseNav }, ref) => {
    return (
      <nav
        ref={ref}
        css={[
          global`layout.container`,
          tw`fixed inset-x-0 bottom-0 flex flex-col items-start pt-6 overflow-x-hidden overflow-y-auto transition-all duration-300 ease-out bg-transparent opacity-0 pointer-events-none top-mobile-header pb-7`,
          isOpened && tw`z-10 flex opacity-100 pointer-events-auto bg-secondary-500`,
        ]}
      >
        <div css={tw`flex flex-col justify-between`}>
          <ul css={tw`space-y-4`}>
            {primaryLinks.map((link) => (
              <li key={link.label}>
                <Link to={link.link} onClick={forceCloseNav}>
                  <Heading
                    content={link.label}
                    headingType="h4"
                    style={tw`font-medium text-primary-500`}
                  />
                </Link>
                {link.subLinks && link.subLinks.length ? (
                  <ul css={tw`pt-4 space-y-3 pb-9`}>
                    {link.subLinks.map((sublink, i) => {
                      if (i === 4) return null // TODO: Very hack fix, please fix properly
                      return (
                        <li key={sublink.label}>
                          <Link to={sublink.link} onClick={forceCloseNav}>
                            <Heading
                              content={sublink.label}
                              headingType="h3"
                              style={css`
                                ${tw`font-light`}
                              `}
                            />
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
          <footer css={tw`mt-16`}>
            <div css={tw`relative py-6`}>
              <div
                css={css`
                  left: calc((100vw - 48px) / -19.2);
                  ${tw`absolute top-0 w-screen h-px bg-primary-500 bg-opacity-15`};
                `}
              />
              <Heading
                content="Legal information"
                headingType="h6"
                style={tw`mb-2 font-medium text-primary-500`}
              />
              <ul css={tw`flex flex-row space-x-6`}>
                {secondaryLinks &&
                  secondaryLinks.map((link) => (
                    <li key={link.label}>
                      <Link to={link.link} onClick={forceCloseNav}>
                        <Heading
                          content={link.label}
                          headingType="h6"
                          style={tw`text-primary-500`}
                        />
                      </Link>
                    </li>
                  ))}
              </ul>
              <div
                css={css`
                  left: calc((100vw - 48px) / -19.2);
                  ${tw`absolute bottom-0 w-screen h-px bg-primary-500 bg-opacity-15`};
                `}
              />
            </div>
            <div css={tw`flex flex-row items-center space-x-12 py-7`}>
              <Text content="Follow us" style={tw`text-base font-medium text-primary-500`} />
              {socialMedia &&
                socialMedia.map((s) => (
                  <Link key={s.title} to={s.accountLink.link} style={tw`mr-12 last-of-type:mr-0`}>
                    <img
                      src={s.image.url}
                      title={s.title}
                      alt={s.alt}
                      css={css`
                        ${tw`max-h-social-icon`}
                        filter: brightness(0) saturate(100%) invert(15%) sepia(22%) saturate(3594%)
                          hue-rotate(223deg) brightness(88%) contrast(117%);
                      `}
                    />
                  </Link>
                ))}
            </div>
            <div
              css={css`
                line-height: 20px;
                letter-spacing: 0.0025em;
                ${tw`text-sm text-primary-500`}
              `}
            >
              {copyright}
            </div>
          </footer>
        </div>
      </nav>
    )
  }
)

MobileMenu.propTypes = {
  isOpened: PropTypes.bool,
  primaryLinks: PropTypes.arrayOf(LinkType),
  secondaryLinks: PropTypes.arrayOf(LinkType),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
  forceCloseNav: PropTypes.func,
}

const MainNav = ({
  primaryLinks,
  secondaryLinks,
  socialMedia,
  copyright,
  isOpened,
  setIsOpened,
  handleHover,
  headerTransition,
  headerTransitionColor,
}) => {
  const itemsRef = React.createRef()
  const [detectScroll, setDetectScroll] = useState(false)
  const toggleNav = useCallback(
    (val) => {
      setIsOpened(val)
      if (!val) itemsRef.current.scrollTo(0, 0)
    },
    [itemsRef]
  )

  const forceCloseNav = useCallback(() => toggleNav(false), [toggleNav])

  const handleScroll = () => {
    if (window.pageYOffset === 0) {
      setDetectScroll(false)
    } else {
      setDetectScroll(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      {isOpened && (
        <Global
          styles={css`
            body {
              ${max.lg} {
                ${tw`overflow-hidden`}
              }
            }
          `}
        />
      )}
      <nav css={tw`hidden lg:(w-full h-full flex)`}>
        <ul
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
          css={[
            css`
              ${tw`lg:(flex flex-row space-x-6) xl:space-x-11`}
              li > button > span, li > a > span {
                ${tw`text-white`}
              }
              &:hover {
                li > button > span,
                li > a > span {
                  ${tw`text-primary-500`}
                }
                svg {
                  ${tw`fill-secondary`}
                }
              }
            `,
            (headerTransition && detectScroll) || headerTransitionColor === 'navy'
              ? css`
                  li > button > span,
                  li > a > span {
                    ${tw`text-primary-500`}
                  }
                  svg {
                    ${tw`fill-primary`}
                  }
                `
              : css`
                  li > button > span,
                  li > a > span {
                    ${tw`text-white`}
                  }
                  svg {
                    ${tw`fill-white`}
                  }
                `,
            !headerTransition &&
              css`
                li > button > span,
                li > a > span {
                  ${tw`text-primary-500`}
                }
              `,
          ]}
        >
          {primaryLinks &&
            primaryLinks.map((l) =>
              l.subLinks && l.subLinks.length ? (
                <DropdownNavItem key={l.label} item={l} />
              ) : (
                <NavItem key={l.label} item={l} />
              )
            )}
        </ul>
      </nav>
      <div css={tw`flex items-center justify-end lg:hidden`}>
        <button type="button" onClick={() => toggleNav(!isOpened)}>
          <Hamburger
            isOpened={isOpened}
            color={
              headerTransitionColor === 'white' && !isOpened && !detectScroll
                ? theme`colors.white`
                : theme`colors.primary.500`
            }
          />
        </button>
        <MobileMenu
          ref={itemsRef}
          isOpened={isOpened}
          primaryLinks={primaryLinks}
          secondaryLinks={secondaryLinks}
          socialMedia={socialMedia}
          copyright={copyright}
          forceCloseNav={forceCloseNav}
        />
      </div>
    </>
  )
}

MainNav.propTypes = {
  primaryLinks: PropTypes.arrayOf(LinkType),
  secondaryLinks: PropTypes.arrayOf(LinkType),
  socialMedia: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.string,
  isOpened: PropTypes.bool,
  setIsOpened: PropTypes.func,
  handleHover: PropTypes.func,
  headerTransition: PropTypes.bool,
  headerTransitionColor: PropTypes.oneOf(['white', 'navy']),
}

export default MainNav
